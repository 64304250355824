:root { 
--gray_600_33:#8f7b7033; 
--deep_orange_A200:#f3762f; 
--deep_purple_900:#0400d4; 
--blue_A700:#106cf7; 
--red_400:#e5674b; 
--pink_A200_19:#f531b319; 
--gray_50:#fcf8f4; 
--black_903:#0e0e0e; 
--yellow_900_cc:#f28b31cc; 
--yellow_900_33:#f28b3133; 
--pink_A200:#f531b3; 
--black_900:#000000; 
--black_902:#191000; 
--yellow_900:#f28b31; 
--orange_A200_7f:#ffa54c7f; 
--purple_A200:#c226de; 
--deep_purple_A200:#9747ff; 
--purple_A100:#ec7aff; 
--black_900_26:#00000026; 
--gray_700:#5f6368; 
--gray_301:#dadce0; 
--gray_400:#babcbe; 
--orange_A201:#f59b4c; 
--orange_A200:#ffa54c; 
--gray_401:#c0bcb6; 
--gray_901:#202124; 
--gray_800:#494c4f; 
--gray_900:#1b1b1b; 
--gray_801:#3c4043; 
--bluegray_100:#cecece; 
--orange_300:#ffae5d; 
--gray_300:#dee1e6; 
--orange_200:#fbbf87; 
--bluegray_400_63:#8c8c8c63; 
--gray_100:#f1f3f4; 
--orange_100:#ffd1a3; 
--bluegray_900:#312f2d; 
--gray_900_3f:#1b1b1b3f; 
--yellow_900_00:#f28b3100; 
--orange_A200_19:#ffa54c19; 
--yellow_900_87:#f28b3187; 
--white_A700_26:#ffffff26; 
--bluegray_400:#8c8c8c; 
--blue_200:#a0c6ff; 
--white_A700:#ffffff; 
--bluegray_400_9e:#8c8c8c9e; 
--bluegray_901:#2b2b2b; 
--orange_111:#F28B31;
--orange_222:#F59B4C;
--scroll_default: linear-gradient(145deg, rgba(27,27,27,0.45) 6.25%,rgba(44,25,19,0.30) 94.02%);
--scroll_hover: linear-gradient(145deg, rgba(27,27,27,0.72) 6.25%, rgba(44,25,19,0.48)94.02%); 
}