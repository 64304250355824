
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/PoppinsSemiBold.ttf"); font-family : "Poppins"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/PoppinsMedium.ttf"); font-family : "Poppins"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/PoppinsRegular.ttf"); font-family : "Poppins"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/PoppinsLight.ttf"); font-family : "Poppins"; font-weight : 300;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/PoppinsBold.ttf"); font-family : "Poppins"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : italic; src: url("assets/fonts/PoppinsSemiBoldItalic.ttf"); font-family : "Poppins"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/RobotoRegular.ttf"); font-family : "Roboto"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/DMSansMedium.ttf"); font-family : "DM Sans"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/DMSansBold.ttf"); font-family : "DM Sans"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/DMSansRegular.ttf"); font-family : "DM Sans"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/RobotoBold.ttf"); font-family : "Roboto"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterRegular.ttf"); font-family : "Inter"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/MontserratBold.ttf"); font-family : "Montserrat"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/FiraMonoRegular.ttf"); font-family : "Fira Mono"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : bold; src: url("assets/fonts/FiraMonoMedium.ttf"); font-family : "Fira Mono"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : italic; src: url("assets/fonts/PoppinsItalic.ttf"); font-family : "Poppins"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : italic; src: url("assets/fonts/FiraSansBoldItalic.ttf"); font-family : "Fira Sans"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : italic; src: url("assets/fonts/PoppinsLightItalic.ttf"); font-family : "Poppins"; font-weight : 300;  }