input::placeholder { /* CSS3规范，适用于Chrome、Safari、Opera */
    color: #8c8c8c;
  }
  
input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
color: #8c8c8c;
}

input::-moz-placeholder { /* Firefox 19+ */
color: #8c8c8c;
}

input:-ms-input-placeholder { /* IE 10+ */
color: #8c8c8c;
}

input:-moz-placeholder { /* Firefox 18- */
color: #8c8c8c;
}

::-webkit-scrollbar {
  display: none
}